<template>
  <div>404</div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style>
</style>
